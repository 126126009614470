import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from '@ameroservices-platform/attraction-frontend/app/store/fuse/messageSlice';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import { createUserSettingsFirebase, setUserData } from './userSlice';

export const registerWithFirebase = model => async dispatch => {
	if (!firebaseService.auth) {
		console.warn("Firebase Service didn't initialize, check your configuration");

		return () => false;
	}
	const { email, password, passwordConfirm, displayName } = model;

	if (password !== passwordConfirm) {
		return dispatch(
			registerError({
				password: 'AUTH_PASSWORDS_DO_NOT_MATCH',
				passwordConfirm: 'AUTH_PASSWORDS_DO_NOT_MATCH'
			})
		);
	}

	return firebaseService.auth
		.createUserWithEmailAndPassword(email, password)
		.then(response => {
			dispatch(
				createUserSettingsFirebase({
					...response.user,
					displayName,
					email
				})
			);

			return dispatch(registerSuccess());
		})
		.catch(error => {
			const usernameErrorCodes = ['auth/operation-not-allowed', 'auth/user-not-found', 'auth/user-disabled'];

			const emailErrorCodes = ['auth/email-already-in-use', 'auth/invalid-email'];

			const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];

			const response = {
				email: emailErrorCodes.includes(error.code) ? error.code.toUpperCase().replaceAll(/[-/]/g, '_') : null,
				displayName: usernameErrorCodes.includes(error.code)
					? error.code.toUpperCase().replaceAll(/[-/]/g, '_')
					: null,
				password: passwordErrorCodes.includes(error.code)
					? error.code.toUpperCase().replaceAll(/[-/]/g, '_')
					: null
			};

			if (error.code === 'auth/invalid-api-key') {
				dispatch(showMessage({ message: error.message }));
			}

			return dispatch(registerError(response));
		});
};

const initialState = {
	success: false,
	error: {
		email: null,
		displayName: null,
		password: null
	}
};

const registerSlice = createSlice({
	name: 'auth/register',
	initialState,
	reducers: {
		registerSuccess: (state, action) => {
			state.success = true;
		},
		registerError: (state, action) => {
			state.success = false;
			state.error = action.payload;
		}
	},
	extraReducers: {}
});

export const checkCustomer = async email => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerCheckCustomerEmail', {
		email
	});
	return resp.data;
};

export const sendConfirmationMail = async email => {
	const currentLanguageLocalStorage = JSON.parse(localStorage.getItem('language'));
	const resp = await firebaseService.callFunctionByName('organisationCustomerSendConfirmationMail', {
		email,
		type: 'customer-create',
		language:
			currentLanguageLocalStorage && currentLanguageLocalStorage.isoCode
				? currentLanguageLocalStorage.isoCode
				: null
	});
	return resp.data;
};

export const { registerSuccess, registerError } = registerSlice.actions;

export default registerSlice.reducer;
