import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import { setOrderLinesForGoogleTagManger } from '../../services/googleTagManagerService/googleTagManagerService';

const cartSlice = createSlice({
	name: 'cartApp/cart',
	initialState: {
		customerId: null,
		orderId: null,
		order: null,
		orderDraft: null,
		events: {},
		orderLines: [],
		orderLoading: false,
		orderDraftLoading: false,
		allOrderLinesLoading: false,
		idsLoading: false,
		totalsLoading: false,
		orderLinesLoading: [],
		finished: false,
		error: null,
		maxError: false,
		codeLoading: false,
		eventGroups: {},
		eventGroupSummaries: {},
		selectedUpsellProducts: {},
		selectedUpsellEvents: {}
	},
	reducers: {
		setOrderId: {
			reducer: (state, action) => {
				state.orderId = action.payload;
				state.orderlinesLoaded = false;
			}
		},
		setCustomerId: {
			reducer: (state, action) => {
				state.customerId = action.payload;
			}
		},
		setOrderDraftLoading(state, action) {
			state.orderDraftLoading = action.payload;
		},
		setCodeLoading(state, action) {
			state.codeLoading = action.payload;
		},
		setOrderLoading(state, action) {
			state.orderLoading = action.payload;
		},
		setIdsLoading(state, action) {
			state.idsLoading = action.payload;
		},
		setAllOrderLinesLoading(state, action) {
			state.allOrderLinesLoading = action.payload;
		},
		setTotalsLoading(state, action) {
			state.totalsLoading = action.payload;
		},
		addOrderLinesLoading(state, action) {
			state.orderLinesLoading = [...state.orderLinesLoading, ...action.payload];
		},
		removeOrderLinesLoading(state) {
			state.orderLinesLoading = [];
		},
		setOrder(state, action) {
			state.order = action.payload;
		},
		setOrderDraft(state, action) {
			state.orderDraft = action.payload;
		},
		setEvents(state, action) {
			state.events = action.payload;
		},
		setOrderLines(state, action) {
			// const { orderLines } = current(state);

			setOrderLinesForGoogleTagManger(action.payload);

			state.orderLines = action.payload.map(ol => ({
				...ol,
				unitPrice: ol.unitPrice,
				linePrice: ol.linePrice
			}));
		},
		setFinished(state, action) {
			state.finished = action.payload;
		},
		setError(state, action) {
			state.error = action.payload;
		},
		setMaxError(state, action) {
			state.maxError = action.payload;
		},
		addOrderLine: {
			reducer: (state, action) => {
				state.orderLines.push(action.payload);
			},
			prepare: event => ({ payload: event })
		},
		setEventGroup(state, action) {
			if (action.payload.content && typeof action.payload.content === 'string') {
				action.payload.content = JSON.parse(action.payload.content);
			}
			state.eventGroups[action.payload.id] = action.payload;
		},
		setEventGroupSummary(state, action) {
			state.eventGroupSummaries[action.payload.eventGroupUid] = action.payload.summaries;
		},
		setSelectedUpsellProduct(state, action) {
			if (!state.selectedUpsellProducts[action.payload.eventGroupUid]) {
				state.selectedUpsellProducts[action.payload.eventGroupUid] = {};
			}
			state.selectedUpsellProducts[action.payload.eventGroupUid][action.payload.productUid] =
				action.payload.amount;
		},
		setSelectedUpsellEvent(state, action) {
			state.selectedUpsellEvents[action.payload.eventGroupUid] = action.payload.eventUid;
		},
		setSelectedUpsellProducts(state, action) {
			state.selectedUpsellProducts = action.payload;
		},
		setSelectedUpsellEvents(state, action) {
			state.selectedUpsellEvents = action.payload;
		}
	}
});

export const {
	setOrderLines,
	setOrder,
	setCustomerId,
	setOrderId,
	addOrderLinesLoading,
	removeOrderLinesLoading,
	setTotalsLoading,
	setEvents,
	setFinished,
	setOrderLoading,
	setOrderDraftLoading,
	setAllOrderLinesLoading,
	setError,
	setMaxError,
	setIdsLoading,
	setOrderDraft,
	setCodeLoading,
	setEventGroup,
	setEventGroupSummary,
	setSelectedUpsellProduct,
	setSelectedUpsellEvent,
	setSelectedUpsellProducts,
	setSelectedUpsellEvents
} = cartSlice.actions;

export default cartSlice.reducer;

export const getIds = async (orderUid = null) => {
	const currentLanguageLocalStorage = localStorage.getItem('language')
		? JSON.parse(localStorage.getItem('language'))
		: null;
	const resp = await firebaseService.callFunctionByName('organisationCustomerCartGetIds', {
		orderUid,
		language:
			currentLanguageLocalStorage && currentLanguageLocalStorage.isoCode
				? currentLanguageLocalStorage.isoCode
				: null
	});
	return resp.data;
};
export const requestIds = () => async dispatch => {
	dispatch(setIdsLoading(true));
	const currentLanguageLocalStorage = localStorage.getItem('language')
		? JSON.parse(localStorage.getItem('language'))
		: null;
	const resp = await firebaseService.callFunctionByName('organisationCustomerCartGetIds', {
		language:
			currentLanguageLocalStorage && currentLanguageLocalStorage.isoCode
				? currentLanguageLocalStorage.isoCode
				: null
	});
	dispatch(setCustomerId(resp.data.customerId));
	dispatch(setOrderId(resp.data.orderId));
	dispatch(setIdsLoading(false));
};

export const requestOrderDraft = (customerUid, orderUid) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orderDrafts')
		.doc(orderUid)
		.get()
		.then(q => {
			const orderData = q.data();
			delete orderData.createdAt;
			delete orderData.updatedAt;
			dispatch(setOrderDraft({ ...orderData, id: q.id }));
			dispatch(setOrderDraftLoading(false));
		});
};

export const cloneOrder = async (customerUid, orderUid) => {
	await firebaseService.callFunctionByName('organisationCustomerCartCloneCart', {
		customerUid,
		orderUid
	});
};

export const orderListener = (customerUid, orderUid) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.doc(orderUid)
		.onSnapshot(q => {
			const orderData = q.data();
			delete orderData.createdAt;
			delete orderData.updatedAt;
			if (orderData.payments) {
				orderData.payments = orderData.payments.map(p => {
					delete p.date;
					delete p.updatedAt;
					return p;
				});
			}
			dispatch(setOrder({ ...orderData, id: q.id }));
			dispatch(setTotalsLoading(false));
			dispatch(setOrderLoading(false));
		});
};

export const orderLinesListener = (customerUid, orderUid) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.doc(orderUid)
		.collection('orderLines')
		.where('deleted', '==', false)
		.onSnapshot(q => {
			const orderLines = [];
			q.forEach(o => {
				orderLines.push({ ...o.data(), id: o.id });
			});
			orderLines.sort(
				(a, b) =>
					(a && a.createdAt && a.createdAt.toDate ? a.createdAt.toDate() : 0) -
					(b && b.createdAt && b.createdAt.toDate ? b.createdAt.toDate() : 0)
			);
			dispatch(setOrderLines(orderLines));
			dispatch(removeOrderLinesLoading());
			if (orderLines.length > 0) {
				dispatch(setAllOrderLinesLoading(false));
			}
		});
};

export const getEventsByUids = eventUids => async dispatch => {
	const db = firebaseService.getRootDB();
	const eventDocs = await Promise.all(
		eventUids.map(eventUid => {
			return db
				.collectionGroup('events')
				.where('organisationUid', '==', firebaseService.getOrganisationId())
				.where('deleted', '==', false)
				.where('uid', '==', eventUid)
				.get();
		})
	);
	const es = {};
	eventDocs.forEach(eventDoc => {
		eventDoc.forEach(o => {
			const data = o.data();
			es[o.id] = { ...data, id: o.id, start: data.start.toMillis(), end: data.end.toMillis() };
		});
	});
	dispatch(setEvents(es));
};

export const addProductsToOrder = lines => async dispatch => {
	dispatch(setAllOrderLinesLoading(true));
	try {
		await firebaseService.callFunctionByName(
			'organisationCustomerCartOrderlineCreateOrderline',
			{
				lines
			},
			true
		);
	} catch (e) {
		if (e.message === 'Not enough tickets available') {
			dispatch(
				setError(
					'Der er ikke nok billetter tilgængelige i den valgte kombination på denne dato. Prøv en anden dato eller reguler antallet af billetter'
				)
			);
		} else if (e.message === 'Event is needed for this product') {
			dispatch(setError('Det valgte produkt kræver en begivenhed. Prøv igen'));
		} else if (e.message === 'Product is not active or is deleted') {
			dispatch(setError('Det valgte produkt findes ikke længere. Prøv igen'));
		}
	} finally {
		dispatch(setAllOrderLinesLoading(false));
	}
};

export const deleteOrderLine = (orderLineUid, type) => async dispatch => {
	dispatch(setAllOrderLinesLoading(true));
	if (type === 'code') {
		dispatch(setCodeLoading(true));
	}
	await firebaseService.callFunctionByName('organisationCustomerCartOrderlineDeleteOrderline', {
		orderLineUid
	});
	dispatch(setAllOrderLinesLoading(false));
	if (type === 'code') {
		dispatch(setCodeLoading(false));
	}
};

export const updateAmountOnOrderLine = (orderLines, orderLineId, amount) => async dispatch => {
	const index = orderLines.findIndex(ol => ol.id === orderLineId);
	const newOrderLines = [...orderLines];
	const oldAmount = newOrderLines[index].qty;
	newOrderLines[index] = { ...newOrderLines[index], qty: amount };
	dispatch(setOrderLines(newOrderLines));
	dispatch(setTotalsLoading(true));
	dispatch(addOrderLinesLoading([orderLineId, 'totals']));
	try {
		await firebaseService.callFunctionByName(
			'organisationCustomerCartOrderlineUpdateOrderline',
			{
				qty: amount,
				orderLineUid: orderLineId
			},
			true
		);
	} catch (e) {
		dispatch(
			setError(
				'Der er ikke nok billetter tilgængelige i den valgte kombination på denne dato. Prøv en anden dato eller reguler antallet af billetter'
			)
		);
		const newOrderLines2 = [...orderLines];
		newOrderLines2[index] = { ...newOrderLines2[index], qty: oldAmount };
		dispatch(setOrderLines(newOrderLines2));
		dispatch(setTotalsLoading(false));
		dispatch(removeOrderLinesLoading());
	}
};

export const updateOrderDraft = (customerUid, orderUid, data) => async dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	await db.collection('customers').doc(customerUid).collection('orderDrafts').doc(orderUid).update(data);
};

export const verifyOrder = () => async dispatch => {
	try {
		await firebaseService.callFunctionByName('organisationCustomerCartVerifyCart', {}, true);
		dispatch(setMaxError(false));
	} catch (e) {
		if (e && e.details && e.details.detailedCode === 'notEnoughTicketsAvailable') {
			dispatch(setMaxError(false));
			dispatch(
				setError(
					`Der er desværre ikke flere pladser på ${e.details.events.join(
						', '
					)}. Billetterne er derfor blevet fjernet fra din kurv.`
				)
			);
		} else if (e && e.details && e.details.detailedCode === 'inactiveOrDeletedProduct') {
			dispatch(setMaxError(false));
			dispatch(
				setError(
					`Produkt ${e.details.products.join(', ')} er desværre udsolgt, og er derfor fjernet fra din kurv.`
				)
			);
		} else if (e && e.details && e.details.detailedCode === 'orderNotFound') {
			dispatch(setMaxError(false));
			dispatch(
				setError(
					`Produkt ${e.details.products.join(', ')} er desværre udsolgt, og er derfor fjernet fra din kurv.`
				)
			);
		} else if (e && e.details && e.details.detailedCode === 'tooManyOrderLines') {
			dispatch(setMaxError(true));
		} else {
			dispatch(setMaxError(false));
			dispatch(setError(`Der er sket en ukendt fejl, prøv igen.`));
		}
		console.error(e);
	}
};
export const applyCode = code => async dispatch => {
	dispatch(setCodeLoading(true));
	try {
		await firebaseService.callFunctionByName('organisationCustomerCartApplyCode', { code }, true);
	} catch (e) {
		dispatch(setError(`Rabatkoden er ugyldig`));
	} finally {
		dispatch(setCodeLoading(false));
	}
};
export const updateUpsell = (accept, deny) => async dispatch => {
	try {
		await firebaseService.callFunctionByName('organisationCustomerCartUpdateUpsell', { accept, deny }, true);
	} catch (e) {
		dispatch(setError(`Der er sket en ukendt fejl, prøv igen.`));
	}
};
export const removeCode = code => async dispatch => {
	dispatch(setCodeLoading(true));
	await firebaseService.callFunctionByName('organisationCustomerCartRemoveCode', {
		code
	});
	dispatch(setCodeLoading(false));
};
export const submitOrder = () => async dispatch => {
	dispatch(setFinished(true));
	try {
		const currentLanguageLocalStorage = JSON.parse(localStorage.getItem('language'));
		await firebaseService.callFunctionByName(
			'organisationCustomerCartSubmitCart',
			{
				language:
					currentLanguageLocalStorage && currentLanguageLocalStorage.isoCode
						? currentLanguageLocalStorage.isoCode
						: null
			},
			true
		);
		dispatch(setMaxError(false));
	} catch (e) {
		if (e.details.detailedCode === 'notEnoughTicketsAvailable') {
			dispatch(
				setError(
					`Der er desværre ikke flere pladser på ${e.details.events.join(
						', '
					)}. Billetterne er derfor blevet fjernet fra din kurv.`
				)
			);
			dispatch(setMaxError(false));
			dispatch(setFinished(false));
		} else if (e.details.detailedCode === 'inactiveOrDeletedProduct') {
			dispatch(
				setError(
					`Produkt ${e.details.products.join(', ')} er desværre udsolgt, og er derfor fjernet fra din kurv.`
				)
			);
			dispatch(setMaxError(false));
			dispatch(setFinished(false));
		} else if (e.details.detailedCode === 'tooManyOrderLines') {
			dispatch(setMaxError(true));
			dispatch(setFinished(false));
		} else if (e.details.detailedCode === 'orderNotFound') {
			dispatch(
				setError(
					`Produkt ${e.details.products.join(', ')} er desværre udsolgt, og er derfor fjernet fra din kurv.`
				)
			);
			dispatch(setMaxError(false));
			dispatch(setFinished(false));
		}
	}
};
export const eventGroupsListener = eventGroupUids => dispatch => {
	if (!eventGroupUids || eventGroupUids.length === 0) return [];
	const db = firebaseService.getOrganisationRootDB();
	return eventGroupUids.map(uid =>
		db
			.collection('eventGroups')
			.doc(uid)
			.onSnapshot(doc => {
				dispatch(setEventGroup({ ...doc.data(), id: doc.id }));
			})
	);
};

export const eventGroupSummariesListener = eventGroupUids => dispatch => {
	if (!eventGroupUids || eventGroupUids.length === 0) return [];
	const db = firebaseService.getOrganisationRootDB();
	return eventGroupUids.map(uid =>
		db
			.collection('eventGroups')
			.doc(uid)
			.collection('groupSummaryByMonth')
			.onSnapshot(query => {
				dispatch(
					setEventGroupSummary({
						eventGroupUid: uid,
						summaries: query.docs.map(d => ({ ...d.data(), id: d.id }))
					})
				);
			})
	);
};

export const verifyCustomer = async email => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerVerifyCustomer', { email });
	return resp.data;
};

export const selectOrderId = state => state.shared.cart.orderId;
export const selectCustomerId = state => state.shared.cart.customerId;
export const selectOrderLines = state => state.shared.cart.orderLines;
export const selectOrder = state => state.shared.cart.order;
export const selectOrderDraft = state => state.shared.cart.orderDraft;
export const selectAllOrderLinesLoading = state => state.shared.cart.allOrderLinesLoading;
export const selectOrderLoading = state => state.shared.cart.orderLoading;
export const selectIdsLoading = state => state.shared.cart.idsLoading;
export const selectOrderDraftLoading = state => state.shared.cart.orderDraftLoading;
export const selectOrderLinesLoading = state => state.shared.cart.orderLinesLoading;
export const selectTotalsLoading = state => state.shared.cart.totalsLoading;
export const selectEvents = state => state.shared.cart.events;
export const selectFinished = state => state.shared.cart.finished;
export const selectError = state => state.shared.cart.error;
export const selectMaxError = state => state.shared.cart.maxError;
export const selectCodeLoading = state => state.shared.cart.codeLoading;
export const selectEventGroups = state => state.shared.cart.eventGroups;
export const selectEventGroupSummaries = state => state.shared.cart.eventGroupSummaries;
export const selectSelectedUpsellProducts = state => state.shared.cart.selectedUpsellProducts;
export const selectSelectedUpsellEvents = state => state.shared.cart.selectedUpsellEvents;
