import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FuseNavigation from '@ameroservices-platform/attraction-frontend/fuse/core/FuseNavigation';
import { mergeThemes, selectNavigation, selectTheme } from '../../../../../../store/shared/frontendSlice';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';
import getColor from '../shared/getColor';

function Navigation(props) {
	const { contentElementProps } = props;
	const flags = useFlags();

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		horizontalNavigation: {
			'& .list-item': {
				'& .list-item-text': {
					padding: '0',
					'& .list-item-text-primary': {
						fontWeight: 'inherit',
						fontSize: 'inherit',
						textTransform: 'inherit'
					}
				},
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
				'&.active': {
					backgroundColor: `${getColor(
						contentElementProps.activeBackgroundColor,
						contentElementProps.customActiveBackgroundColor,
						mergedTheme
					)}`,
					color: `${getColor(
						contentElementProps.activeTextColor,
						contentElementProps.customActiveTextColor,
						mergedTheme
					)}!important`,
					borderBottom: `${
						contentElementProps.activeBorderBottomWidth > 0 &&
						`${contentElementProps.activeBorderBottomWidth}px solid ${getColor(
							contentElementProps.activeBorderBottomColor,
							contentElementProps.customActiveBorderBottomColor,
							mergedTheme
						)}`
					}`
				}
			}
		},
		verticalNavigation: {
			'& .list-item': {
				'& .list-item-text': {
					padding: '0',
					'& .list-item-text-primary': {
						fontWeight: 'inherit',
						fontSize: 'inherit',
						textTransform: 'inherit'
					}
				},
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
				'&.active': {
					backgroundColor: `${getColor(
						contentElementProps.activeBackgroundColor,
						contentElementProps.customActiveBackgroundColor,
						mergedTheme
					)}`,
					color: `${getColor(
						contentElementProps.activeTextColor,
						contentElementProps.customActiveTextColor,
						mergedTheme
					)}!important`,
					borderBottom: `${
						contentElementProps.activeBorderBottomWidth > 0 &&
						`${contentElementProps.activeBorderBottomWidth}px solid ${getColor(
							contentElementProps.activeBorderBottomColor,
							contentElementProps.customActiveBorderBottomColor,
							mergedTheme
						)}`
					}`
				}
			}
		}
	}));
	const classes = useStyles(props);

	const navigation = useSelector(selectNavigation);

	const getLocaleFromData = useCallback((object, key) => {
		const currentLanguageLocalStorage = JSON.parse(localStorage.getItem('language'));
		if (
			currentLanguageLocalStorage &&
			currentLanguageLocalStorage.isoCode &&
			object &&
			object[`${key}_${currentLanguageLocalStorage.isoCode}`]
		) {
			return object[`${key}_${currentLanguageLocalStorage.isoCode}`];
		}
		return object[key] || '';
	}, []);

	const languageNavigation = useMemo(() => {
		if (!flags.multiLanguageCmsFrontend) {
			return null;
		}
		const _navigation =
			navigation && navigation[contentElementProps.navigation] ? navigation[contentElementProps.navigation] : [];
		const copyNavigation = [];
		_navigation.forEach(nav => {
			copyNavigation.push({ ...nav, title: getLocaleFromData(nav, 'title') });
		});
		return copyNavigation;
	}, [navigation, contentElementProps, flags.multiLanguageCmsFrontend, getLocaleFromData]);

	return (
		<FuseNavigation
			className={`${clsx('navigation')} text-${contentElementProps.fontSize} font-${
				contentElementProps.fontWeight
			} ${contentElementProps.textTransform}`}
			navigation={
				flags.multiLanguageCmsFrontend
					? languageNavigation
					: navigation && navigation[contentElementProps.navigation]
					? navigation[contentElementProps.navigation]
					: []
			}
			// active="square"
			layout={contentElementProps.layout}
			classes={{
				horizontalNavigation: classes.horizontalNavigation,
				verticalNavigation: classes.verticalNavigation
			}}
		/>
	);
}

export default Navigation;
